<template>
  <div v-if="furigana" class="term-with-furigana">
    <div class="term-with-furigana-reading">{{furigana}}</div>
    <div class="term-with-furigana-term">{{term}}</div>
  </div>
  <span v-else>{{term}}</span>
</template>

<script>
export default {
  props: ["term", "furigana"]
}
</script>
