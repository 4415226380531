import waitForJQuery from 'wait-for-jquery'

waitForJQuery(() => {
  $(".event-listing-prefecture-select").on('change', (e) => {
    var prefectureId = $(e.currentTarget).val()
    var searchParams = new URLSearchParams(window.location.search)

    if(prefectureId) {
      searchParams.set("prefecture_id", prefectureId)
    } else {
      searchParams.delete("prefecture_id")
    }

    searchParams.delete("page")
    window.location.search = searchParams.toString()
  })
})
