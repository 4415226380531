const ja = {
  "({count} checked-in)": " ({count}人がチェックイン済み)",
  "1 person | {count} people": "{count}人",
  "Are you sure you want to undo the check-in for {name}, {email}?": "本当に{name}さん（{email}）のチェックインを取り消しますか？",
  "Attendees": "参加者",
  "Awaiting payment": "支払い待ち",
  "Cancelled": "キャンセル者",
  "Cancelled at": "キャンセル日",
  "Checked-in": "チェックイン済み",
  "Checked-in at": "出席日時",
  "Code": "コード",
  "Code, name, or email": "コード、名前、メールアドレス",
  "Email": "メールアドレス",
  "Extended reservation period because moved off waitlist": "キャンセル待ちから移行したため、支払い期限が延長されました。",
  "Last Check-in": "直近のチェックイン",
  "Loading, please wait ...": "ロード中です。お待ちください。",
  "Name": "名前",
  "Nobody has registered for this event yet.": "このイベントにはまだ誰も申し込んでいません。",
  'No tickets matching "%{query}".': '"%{query}"に該当するチケットはありません。',
  "Outstanding": "チェックイン待ち",
  "Payment deadline": "お支払い期日",
  "Please check your network connection and try again.": "ネットワークの接続を確認し、もう一度やり直してください。",
  "Promotional code applied: %{code}": "割引コードが適用されました: %{code}",
  "Registered at": "お申し込み日",
  "Search participants": "検索",
  "Show all": "展開する",
  "Suggested reservation period; manual interaction with attendee required": "推奨される予約期間。参加者とメールなどで直接連絡をする必要があります。",
  "There was an issue with checking in the following attendees:": "下記の出席者のチェックインができませんでした。",
  "Ticket": "チケット",
  "Waitlisted": "キャンセル待ち",
}
const en = Object.keys(ja).reduce((hash, key) => { hash[key] = key; return hash }, {})

export default { en, ja }
