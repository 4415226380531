import ready from 'ready'

function waitForJQuery(method) {
  if (window.jQuery) {
    method()
  } else {
    setTimeout(function() { waitForJQuery(method) }, 50);
  }
}

export default function(method) {
  ready(function(){ waitForJQuery(method) })
}
