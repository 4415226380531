import autosize from 'autosize'
import waitForJQuery from 'wait-for-jquery'

waitForJQuery(function() {
  document.addEventListener('focus', function(e){
  	if (e.target.nodeName === 'TEXTAREA' && $(e.target).hasClass('autosize') ) {
  		autosize(e.target);
  	}
  }, true)
})
