import ready from 'ready'
import 'lightgallery.js'
import 'lightgallery.js/src/css/lightgallery'
import 'lg-thumbnail.js'

ready(function() {
  var elements = document.querySelectorAll(".community-photo-montage, .community-photo-gallery")
  for (var e of elements) {
    lightGallery(e, {
      thumbnail: true,
      animateThumb: true,
      selector: 'a'
    })
  }
})
