<template>
  <div>
    <loading-indicator />
    <ticket-table v-if="issuedTickets.length > 0" :tickets="issuedTickets" :limit="10000"  :columns="columns" :initialSortBy="'shortCode'" v-slot="{ticketsForDisplay}">
      <tr v-for="ticket in ticketsForDisplay" :id="'ticket-' + ticket.id">
        <td>
          <i class="far fa-2x" :class="[ticket.checkedInAt ? 'fa-check-square' : 'fa-square']"></i>
        </td>
        <td v-if="columns.includes('shortCode')">{{ticket.shortCode}}</td>
        <td><user-profile-for-ticket :ticket="ticket" :linkUser="false" :hideCheckedInFlag="true" :hideUserAvatar="true"></user-profile-for-ticket></td>
        <td><payment-info :ticket="ticket" /></td>
      </tr>
    </ticket-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('tickets')

import LoadingIndicator from 'shared/components/loading-indicator.vue'
import TicketTable from 'shared/components/ticket-table.vue'
import PaymentInfo from 'shared/components/payment-info.vue'
import UserProfileForTicket from 'shared/components/user-profile-for-ticket.vue'

export default {
  components: { LoadingIndicator, TicketTable, PaymentInfo, UserProfileForTicket },
  props: ["ticketsUrl"],
  computed: {
    ...mapGetters(['issuedTickets']),
    event() {
      var firstTicket = this.issuedTickets[0]
      return firstTicket ? firstTicket.event : null
    },
    columns() {
      if (this.event.online) {
        return ['actions', 'userName', 'ticketType']
      } else {
        return ['actions', 'shortCode', 'userName', 'ticketType']
      }
    }
  },
  mounted() {
    this.pollTickets({element: this.$el, ticketsUrl: this.ticketsUrl})
  },
  methods: {
    ...mapActions(['pollTickets']),
  }
}
</script>
