<template>
  <tr v-bind:class="classObject" :id="'ticket-' + ticket.id">
    <td>
      <div v-if="saving" class="spinner">
        <i class="fas fa-fw fa-sync fa-spin" />
      </div>
      <template v-else>
        <a v-if="savingFailed" class="text-danger" v-on:click="checkIn">
          <i class="fas fa-fw fa-exclamation-circle" />
        </a>
        <a v-else v-on:click="checkIn">
          <i class="far fa-fw" :class="[isCheckedIn ? 'fa-check-square' : 'fa-square']"></i>
        </a>
      </template>
    </td>
    <td v-if="columns.includes('shortCode')">{{ticket.shortCode}}</td>
    <td>
      <user-profile-for-ticket :linkUser="false" :hideCheckedInFlag="true" :ticket="ticket"></user-profile-for-ticket>
    </td>
    <td><payment-info :ticket="ticket"></payment-info></td>
    <td><formatted-time :time="ticket['checkedInAt']"></formatted-time></td>
  </tr>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('tickets')
import FormattedTime from 'shared/components/formatted-time.vue'
import PaymentInfo from 'shared/components/payment-info.vue'
import UserProfileForTicket from 'shared/components/user-profile-for-ticket.vue'
export default {
  components: { FormattedTime, PaymentInfo, UserProfileForTicket },
  data() {
    return {
      savingState: "unsaved"
    }
  },
  props: ["ticket", "columns"],
  computed: {
    saving(){
      return this.ticket.savingState == "saving"
    },
    savingFailed() {
      return this.ticket.savingState == "failed"
    },
    isCheckedIn(){
      return !!this.ticket.checkedInAt
    },
    classObject() {
      return {
        in: this.isCheckedIn,
        out: !this.isCheckedIn,
        busy: this.saving,
      }
    },
  },
  methods: {
    ...mapActions(['callCheckin']),
    checkIn(){
      if (this.saving) {
        return;
      }
      if(this.isCheckedIn){
        var confirmText = this.$t("Are you sure you want to undo the check-in for {name}, {email}?", {name: this.ticket.userName, email: this.ticket.userEmail})
        if(confirm(confirmText)){
          this.callCheckin({ticket: this.ticket, action: "DELETE"})
        }
      } else {
        this.callCheckin({ticket: this.ticket, action: "POST"})
      }
    },
  },
}
</script>
