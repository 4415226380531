<template>
  <div class="payment-info" v-if="!ticket.cancelled">
    {{ticket.paymentInfo}}
    <span v-if="ticket.promoCode" data-toggle="tooltip" :title="$t('Promotional code applied: %{code}', {code: ticket.promoCode.code})">
      <i class="fas fa-info-circle"></i>
    </span>
    <div v-if="multipleTicketTypes">
      <small>{{ticketTypeDescription}}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ticket"],
  computed: {
    ticketTypeDescription() {
      return this.ticket.ticketType.description
    },
    multipleTicketTypes() {
      return this.ticket.event.multipleTicketTypes
    },
  }
}
</script>
