<template>
  <div>
    <loading-indicator />
    <div v-if="issuedTickets.length > 0">
      <div class="row">
        <div class="col-md-8 col-md-push-4">
          <div class="panel panel-default">
            <table class="table table-condensed">
              <tbody>
                <tr>
                  <th>{{$t("Checked-in")}}</th>
                  <th>{{$t("Outstanding")}}</th>
                  <th>{{$t("Last Check-in")}}</th>
                </tr>
                <tr>
                  <td>
                    <span id="checkinCount">{{checkedInTickets.length}}</span> / <person-count :count="issuedTickets.length" />
                  </td>
                  <td>
                    <person-count id="outstandingCount" :count="outstandingTickets.length" />
                  </td>
                  <td>
                    <span id="lastCheckedInTicket" v-if="lastCheckedInTicket">{{lastCheckedInTicket.userName}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-4 col-md-pull-8">
          <h4 style="margin-top: 0">{{$t('Search participants')}}</h4>
          <search-tickets :placeholder="$t('Code, name, or email')"></search-tickets>
        </div>
      </div>

      <div class="alert alert-danger" v-if="savingFailedTickets.length > 0">
        <p>{{ $t("There was an issue with checking in the following attendees:") }}</p>
        <ul>
          <li v-for="ticket in savingFailedTickets">{{ticket.userName}}</li>
        </ul>
        {{ $t("Please check your network connection and try again.") }}
      </div>

      <ticket-table :tickets="filteredIssuedTickets" :limit=100 :columns="columns" v-slot="{ticketsForDisplay}" class="checkin-table">
        <check-in-row v-for="ticket in ticketsForDisplay" :key="ticket.id" :ticket="ticket" :columns="columns"></check-in-row>
      </ticket-table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('tickets')

import CheckInRow from 'check-in/components/check-in-row.vue'
import TicketTable from 'shared/components/ticket-table.vue'
import LoadingIndicator from 'shared/components/loading-indicator.vue'
import SearchTickets from 'shared/components/search-tickets.vue'
import PersonCount from 'shared/components/person-count.vue'

export default {
  components: { CheckInRow, TicketTable, LoadingIndicator, SearchTickets, PersonCount },
  props: ["ticketsUrl"],
  computed: {
    ...mapGetters(['issuedTickets', 'checkedInTickets', 'filteredIssuedTickets', 'outstandingTickets', 'savingFailedTickets']),
    lastCheckedInTicket() {
      var sortedByCheckedInAt = this.checkedInTickets.sort((a, b) => a.checkedInAt < b.checkedInAt ? 1 : -1)
      return sortedByCheckedInAt[0]
    },
    event() {
      var firstTicket = this.issuedTickets[0]
      return firstTicket ? firstTicket.event : null
    },
    columns() {
      if (this.event.online) {
        return [ "actions", "userName", "ticketType", "checkedInAt" ]
      } else {
        return [ "actions", "shortCode", "userName", "ticketType", "checkedInAt" ]
      }
    }
  },
  mounted() {
    this.pollTickets({element: this.$el, ticketsUrl: this.ticketsUrl})
  },
  methods: {
    ...mapActions(['pollTickets']),
  }
}
</script>
