<template>
  <div class="user-profile user-profile-mini">
    <div class="user-profile-details">

      <img v-if="!hideUserAvatar" v-bind:src="ticket.userAvatarUrl" width="25" height="25" v-bind:alt="ticket.userName" class="user-avatar hidden-xs">
      <div>
        <div v-if="linkUser" class="user-name">
          <a v-bind:href="ticket.adminTicketUrl">
            <term-with-furigana v-bind:term="ticket.userName" v-bind:furigana="ticket.userNameFurigana"></term-with-furigana>
          </a>
        </div>
        <div v-else class="user-name">
          <term-with-furigana v-bind:term="ticket.userName" v-bind:furigana="ticket.userNameFurigana"></term-with-furigana>
        </div>
        <small v-if="!hideEmail">{{ticket.userEmail}}</small>
      </div>
    </div>
    <i v-if="displayCheckedInFlag" class="fas fa-check checkedin-flag" data-toggle="tooltip" v-bind:title="$t('Checked-in')"></i>
  </div>
</template>

<script>
import TermWithFurigana from 'shared/components/term-with-furigana.vue'
export default {
  components: { TermWithFurigana },
  props: {
    ticket: Object,
    linkUser: {
      type: Boolean,
      default: true
    },
    hideCheckedInFlag: {
      type: Boolean,
      default: false
    },
    hideEmail: {
      type: Boolean,
      default: false
    },
    hideUserAvatar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayCheckedInFlag(){
      return !this.hideCheckedInFlag && this.ticket.checkedInAt
    }
  }
}
</script>
