import camelCase from 'camelcase'

function deserializeObject(object) {
  var deserializedObject = {
    id: object.id,
    type: object.type,
  }
  var attributes = object.attributes
  Object.keys(attributes).forEach((attribute) => {
    deserializedObject[camelCase(attribute)] = attributes[attribute]
  })
  return deserializedObject
}

function deserializeObjectAndRelationships(object, includes) {
  var deserializedObject = deserializeObject(object)

  var relationships = object.relationships
  Object.keys(relationships).forEach((relationshipAttribute) => {
    var relationshipData = relationships[relationshipAttribute].data
    deserializedObject[camelCase(relationshipAttribute)] = includes[relationshipData.type][relationshipData.id]
  })

  return deserializedObject
}

export default function(data) {
  var deserializedIncludes = {}

  if(data.included) {
    data.included.forEach((object) => {
      if (!deserializedIncludes[object.type]) {
        deserializedIncludes[object.type] = {}
      }
      deserializedIncludes[object.type][object.id] = deserializeObject(object)
    })
  }

  if (Array.isArray(data.data)){
    return data.data.map((object) => {
      return deserializeObjectAndRelationships(object, deserializedIncludes)
    })
  } else {
    return deserializeObjectAndRelationships(data.data, deserializedIncludes)
  }
}
