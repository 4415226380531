export default {
  data() {
    return {
      truncate: true
    }
  },
  computed: {
    truncatedTickets(){
      if(this.truncate) {
        return this.sortedTickets.slice(0, this.limit)
      } else {
        return this.sortedTickets
      }
    },
    isTruncated() {
      return this.truncate && this.limit < this.tickets.length
    }
  },
  methods: {
    showAll(event) {
      this.truncate = false
    },
  }
}
