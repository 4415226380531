import Vue from 'vue/dist/vue.esm'
import jsonApiDeserializer from 'json-api-deserializer'
import {toKatakana} from 'wanakana'
export default {
  namespaced: true,
  state: {
    loaded: false,
    tickets: [],
    query: null,
  },
  getters: {
    filteredTickets(state) {
      if (state.query && state.query.length >= 2) {
        var katakanaOnly =
        toKatakana(state.query).replace(/[^\u30A0-\u30FF]/g, "");
        var regexp = katakanaOnly.length > 0 ? new RegExp(`${state.query}|${katakanaOnly}`, 'i') : new RegExp(state.query, 'i');

        var sc_regexp = new RegExp(`^${state.query}`, 'i');
        return state.tickets.filter((ticket) => {
          return regexp.test(ticket.userName) || regexp.test(ticket.userEmail) || sc_regexp.test(ticket.shortCode) || regexp.test(ticket.userNameFurigana);
        });
      } else {
        return state.tickets;
      }
    },

    issuedTickets(state) {
      return state.tickets.filter(ticket => ticket.issued)
    },
    filteredIssuedTickets(state, getters) {
      return getters.filteredTickets.filter(ticket => ticket.issued)
    },

    checkedInTickets(state, getters) {
      return getters.issuedTickets.filter(ticket => ticket.checkedInAt)
    },
    filteredCheckedInTickets(state, getters) {
      return getters.filteredIssuedTickets.filter(ticket => ticket.checkedInAt)
    },

    outstandingTickets(state, getters) {
      return getters.issuedTickets.filter(ticket => !ticket.checkedInAt)
    },
    filteredOutstandingTickets(state, getters) {
      return getters.filteredIssuedTickets.filter(ticket => !ticket.checkedInAt)
    },

    awaitingPaymentTickets(state) {
      return state.tickets.filter(ticket => ticket.awaitingPayment)
    },
    filteredAwaitingPaymentTickets(state, getters) {
      return getters.filteredTickets.filter(ticket => ticket.awaitingPayment)
    },

    waitlistedTickets(state) {
      return state.tickets.filter(ticket => ticket.waitlisted)
    },
    filteredWaitlistedTickets(state, getters) {
      return getters.filteredTickets.filter(ticket => ticket.waitlisted)
    },

    cancelledTickets(state) {
      return state.tickets.filter(ticket => ticket.cancelled)
    },
    filteredCancelledTickets(state, getters) {
      return getters.filteredTickets.filter(ticket => ticket.cancelled)
    },

    savingFailedTickets(state, getters) {
      return getters.issuedTickets.filter(ticket => ticket.savingState == "failed")
    },
  },
  mutations: {
    setTickets(state, tickets) {
      state.tickets = tickets
    },
    updateTicket(state, object) {
      var index = state.tickets.findIndex(ticket => ticket.id == object.id)
      Vue.set(state.tickets, index, object)
    },
    loaded(state) {
      state.loaded = true
    },
    updateQuery(state, object) {
      state.query = object
    }
  },
  actions: {
    updateQuery({commit}, query) {
      commit('updateQuery', query)
    },
    processJsonPayload({state, commit}, data) {
      var object = jsonApiDeserializer(data)
      if (Array.isArray(object)) {
        object.forEach(ticket => ticket.savingState = null)
        commit('setTickets', object)
      } else if(object.type == "tickets" || object.type == "ticket") {
        object.savingState = null
        commit('updateTicket', object)
      }
    },
    pollTickets({dispatch, commit}, {element, ticketsUrl}){
      var smartupdaterOpts = {
        url: ticketsUrl,
        dataType: 'json',
        minTimeout: 60000,
        httpCache: true,
      }
      $(element).smartupdater(smartupdaterOpts, data => {
        dispatch('processJsonPayload', data)
        commit('loaded')
      })
    },
    callCheckin({dispatch}, {ticket, action}) {
      ticket.savingState = "saving"
      var url = ticket.adminTicketUrl + "/check_in"
      $.ajax({
        url: url,
        type: action,
        dataType: 'json',
        success: (json) => {
          dispatch("processJsonPayload", json)
        },
        error: () => {
          ticket.savingState = "failed";
        }
      })
    },
  }
}
