<template>
  <div v-if="tickets.length > 0">
    <search-tickets :placeholder="$t('Search participants')" class="with-gutter" />
    <ticket-panel :title="$t('Attendees')" :tickets="filteredIssuedTickets">
      <participant-count :totalCapacity="event.totalCapacity" :count="issuedTickets.length" :checkedInCount="checkedInTickets.length" />
    </ticket-panel>
    <ticket-panel :title="$t('Awaiting payment')" :tickets="filteredAwaitingPaymentTickets">
      <person-count :count="awaitingPaymentTickets.length" />
    </ticket-panel>
    <ticket-panel :title="$t('Waitlisted')" :tickets="filteredWaitlistedTickets">
      <person-count :count="waitlistedTickets.length" />
    </ticket-panel>
    <ticket-panel :title="$t('Cancelled')" :tickets="filteredCancelledTickets">
      <person-count :count="cancelledTickets.length" />
    </ticket-panel>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('tickets')

import PersonCount from 'shared/components/person-count.vue'
import ParticipantCount from 'shared/components/participant-count.vue'
import TicketPanel from 'event-dashboard/components/ticket-panel.vue'
import SearchTickets from 'shared/components/search-tickets.vue'

export default {
  props: ["ticketsUrl"],
  components: { ParticipantCount, PersonCount, TicketPanel, SearchTickets },
  computed: {
    ...mapState(['tickets']),
    ...mapGetters(['issuedTickets', 'filteredIssuedTickets', 'checkedInTickets', 'awaitingPaymentTickets', 'filteredAwaitingPaymentTickets', 'waitlistedTickets', 'filteredWaitlistedTickets', 'cancelledTickets', 'filteredCancelledTickets']),
    event() {
      var firstTicket = this.tickets[0]
      return firstTicket ? firstTicket.event : null
    }
  },
  methods: {
    ...mapActions(['pollTickets']),
  },
  mounted: function () {
    this.pollTickets({element: this.$el, ticketsUrl: this.ticketsUrl})
  }
}
</script>
