<template>
  <div v-bind:class="{'text-danger': ticket.processingPayment && reservationOver}">
    <formatted-time :time="ticket.reservationEndsAt"></formatted-time>
    <span v-if="ticket.wasMovedOffWaitlist" data-toggle="tooltip" v-bind:title="$t('Extended reservation period because moved off waitlist')">
      <i class="far fa-clock"></i>
    </span>
    <span v-if="ticket.processingPayment" data-toggle="tooltip" v-bind:title="$t('Suggested reservation period; manual interaction with attendee required')">
      <i v-if="reservationOver" class="fas fa-info"></i>
      <i v-else class="fas fa-info"></i>
    </span>
  </div>
</template>

<script>
import FormattedTime from 'shared/components/formatted-time.vue'
export default {
  components: { FormattedTime },
  props: ["ticket"],
  computed: {
    reservationOver(){
      return moment() > moment(this.ticket.reservationEndsAt)
    },
  }
}
</script>
