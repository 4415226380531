import waitForJQuery from 'wait-for-jquery'
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css'

Dropzone.autoDiscover = false

waitForJQuery(function() {
  var element = $("form.dropzone")
  if (element.length > 0)
    element.dropzone(element.data("dropzoneConfig"))
})
