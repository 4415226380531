import waitForJQuery from 'wait-for-jquery'

waitForJQuery(() => {
  var date = new URLSearchParams(window.location.search).get("since")
  $(".event-listing-datepicker").data({date})
  $(".event-listing-datepicker").datepicker()
  $(".event-listing-datepicker").on('changeDate', (e) => {
    var datePicker = $(e.currentTarget)
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set("since", datePicker.datepicker('getFormattedDate'))
    searchParams.delete("page")
    var spinnerText = datePicker.data("spinnerText")
    if(spinnerText) {
      $.blockUI({
        message: `<i class='fas fa-spinner fa-spin' style='font-size:30px;margin-bottom:10px;'></i><h4>${spinnerText}</h4>`,
        css: { border: 'none', borderRadius: '2px', padding: '50px 0' }
      })
    }
    window.location.search = searchParams.toString()
  })
})
