import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import EventDashboard from 'event-dashboard'
import CheckIn from 'check-in'
import TicketDashboard from 'ticket-dashboard'
import GuestList from 'guest-list'
import messages from "i18n-messages"
import store from "store"
import ready from 'ready'

ready( () => {
  if (document.getElementById("vue-container")) {
    const locale = document.getElementsByTagName('html')[0]["lang"]

    const i18n = new VueI18n({ locale, messages })

    const app = new Vue({
      i18n,
      store,
      el: '#vue-container',
      components: { EventDashboard, CheckIn, TicketDashboard, GuestList}
    })
  }
})
