<template>
  <div class="text-center" v-if="!loaded">
    <p>{{$t("Loading, please wait ...")}}</p>
    <i class="fas fa-spinner fa-spin fa-2x" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('tickets')
export default {
  computed: {
    ...mapState(['loaded']),
  },
}
</script>
