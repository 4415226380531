<template>
  <div>
    <loading-indicator />
    <template v-if="loaded">
      <template v-if="tickets.length == 0">
        <div class="panel">
          <div class="panel-heading">
            <h4 class="panel-title">{{$t('Attendees')}}</h4>
          </div>
          <div class="panel-body">
            {{$t("Nobody has registered for this event yet.")}}
          </div>
        </div>
      </template>
      <template v-else>
        <search-tickets :placeholder="$t('Search participants')" class="with-gutter"></search-tickets>
        <quick-links></quick-links>
        <div v-if="filteredTickets.length == 0">
          {{$t('No tickets matching "%{query}".', {query: query})}}
        </div>

        <ticket-panel id="attendees" :title="$t('Attendees')" :tickets="filteredIssuedTickets">
          <template v-slot:right-item>
            <participant-count :totalCapacity="event.totalCapacity" :count="issuedTickets.length" :checkedInCount="checkedInTickets.length"></participant-count>
          </template>
          <ticket-table :tickets=filteredIssuedTickets :limit=10 :columns=issuedTicketTableColumns :initialSortBy="'registeredAt'" :initialSortDirection="-1" />
        </ticket-panel>

        <ticket-panel id="awaiting-payment" :title="$t('Awaiting payment')" :tickets="filteredAwaitingPaymentTickets">
          <template v-slot:right-item>
            <person-count :count="awaitingPaymentTickets.length" />
          </template>
          <ticket-table :tickets=filteredAwaitingPaymentTickets :limit=10 :columns="['userName', 'registeredAt', 'reservationEndsAt']" :initialSortBy="'reservationEndsAt'" :initialSortDirection="1" />
        </ticket-panel>

        <ticket-panel id="waitlisted" :title="$t('Waitlisted')" :tickets="filteredWaitlistedTickets">
          <template v-slot:right-item>
            <person-count :count="waitlistedTickets.length" />
          </template>
          <ticket-table :tickets=filteredWaitlistedTickets :limit=10 :columns="['userName', 'ticketType', 'registeredAt']"  :initialSortBy="'registeredAt'" :initialSortDirection="-1" />
        </ticket-panel>

        <ticket-panel id="cancelled" :title="$t('Cancelled')" :tickets="filteredCancelledTickets">
          <template v-slot:right-item>
            <person-count :count="cancelledTickets.length" />
          </template>
          <ticket-table :tickets=filteredCancelledTickets :limit=10 :columns="['userName', 'cancelledAt']" :initialSortBy="'cancelledAt'" :initialSortDirection="-1" />
        </ticket-panel>
      </template>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('tickets')

import PersonCount from 'shared/components/person-count.vue'
import ParticipantCount from 'shared/components/participant-count.vue'
import TicketTable from 'shared/components/ticket-table.vue'
import LoadingIndicator from 'shared/components/loading-indicator.vue'
import QuickLinks from 'ticket-dashboard/components/quick-links.vue'
import TicketPanel from 'ticket-dashboard/components/ticket-panel.vue'
import SearchTickets from 'shared/components/search-tickets.vue'

export default {
  components: { PersonCount, ParticipantCount, TicketTable, LoadingIndicator, QuickLinks, SearchTickets, TicketPanel },
  props: ["ticketsUrl"],
  computed: {
    ...mapState(['tickets', 'query', 'loaded']),
    ...mapGetters(['filteredTickets', 'issuedTickets', 'filteredIssuedTickets', 'checkedInTickets', 'awaitingPaymentTickets', 'filteredAwaitingPaymentTickets', 'waitlistedTickets', 'filteredWaitlistedTickets', 'cancelledTickets', 'filteredCancelledTickets']),
    event() {
      var firstTicket = this.tickets[0]
      return firstTicket ? firstTicket.event : null
    },
    issuedTicketTableColumns(){
      if (this.event.online) {
        return ['userName', 'ticketType', 'registeredAt']
      } else {
        return ['userName', 'shortCode', 'ticketType', 'registeredAt']
      }
    }
  },
  mounted() {
    this.pollTickets({element: this.$el, ticketsUrl: this.ticketsUrl})
  },
  methods: {
    ...mapActions(['pollTickets']),
  }
}
</script>
