<template>
  <input id="search" :value="query" @input="updateQueryFromInput" :placeholder="placeholder" class="form-control" v-on:focus="resetSearch">
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('tickets')
export default {
  props: ["placeholder"],
  computed: {
    ...mapState(['query']),
  },
  methods: {
    ...mapActions(['updateQuery']),
    updateQueryFromInput(e) {
      this.updateQuery(e.target.value)
    },
    resetSearch(event) {
      this.updateQuery("")
    },
  },
}
</script>
