<template>
  <div v-if="show" class="with-gutter">
    <a href="#attendees" v-if="filteredIssuedTickets.length > 0" class="btn btn-default">
      {{$t("Attendees")}}
      <span class="badge">{{filteredIssuedTickets.length}}</span>
    </a>
    <a href="#awaiting-payment" v-if="filteredAwaitingPaymentTickets.length > 0" class="btn btn-default">
      {{$t("Awaiting payment")}}
      <span class="badge">{{filteredAwaitingPaymentTickets.length}}</span>
    </a>
    <a href="#waitlisted" v-if="filteredWaitlistedTickets.length > 0" class="btn btn-default">
      {{$t("Waitlisted")}}
      <span class="badge">{{filteredWaitlistedTickets.length}}</span>
    </a>
    <a href="#cancelled" v-if="filteredCancelledTickets.length > 0" class="btn btn-default">
      {{$t("Cancelled")}}
      <span class="badge">{{filteredCancelledTickets.length}}</span>
    </a>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('tickets')
export default {
  computed: {
    ...mapGetters(['issuedTickets', 'filteredIssuedTickets', 'awaitingPaymentTickets', 'filteredAwaitingPaymentTickets', 'waitlistedTickets', 'filteredWaitlistedTickets', 'cancelledTickets', 'filteredCancelledTickets']),
    show(){
      var classifications = [
        this.filteredIssuedTickets,
        this.filteredAwaitingPaymentTickets,
        this.filteredWaitlistedTickets,
        this.filteredCancelledTickets,
      ]
      var classificationsWithTickets = classifications.filter(a => a.length > 0)
      return classificationsWithTickets.length > 0
    }
  }
}
</script>
