import waitForJQuery from 'wait-for-jquery'

waitForJQuery(() => {
  $(document.body).on('change', '.checkbox-list input[type=checkbox]', function(){
    if($(this).is(':checked')) {
      $(this).parent('label').addClass('checked')
    } else {
      $(this).parent('label').removeClass('checked')
    }
  })
  $('.checkbox-list input[type=checkbox]').trigger('change')
})
