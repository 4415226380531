<template>
  <table class="table table-different-row-heights ticket-table">
    <thead>
      <tr>
        <template v-for="key in columns">
          <th v-if="unsortable[key]" :class="'ticket-table-' + key">
            {{labels[key]}}
          </th>
          <th v-else @click="setSortBy(key)" :class="'ticket-table-' + key" class="ticket-table-sortable">
            {{labels[key]}}
            <i v-if="sortBy != key" class="fas fa-sort"></i>
            <i v-else-if="sortDirection == 1" class="fas fa-sort-up"></i>
            <i v-else class="fas fa-sort-down"></i>
          </th>
        </template>
      </tr>
    </thead>
    <tbody>
      <slot :ticketsForDisplay="truncatedTickets">
        <tr v-for="ticket in truncatedTickets">
          <td v-for="key in columns">
            <user-profile-for-ticket v-if="key == 'userName'" :ticket="ticket"></user-profile-for-ticket>
            <payment-info v-else-if="key == 'ticketType'" :ticket="ticket"></payment-info>
            <formatted-time v-else-if="['cancelledAt', 'checkedInAt', 'registeredAt'].includes(key)" :time="ticket[key]"></formatted-time>
            <reservation-ends v-else-if="key == 'reservationEndsAt'" :ticket="ticket"></reservation-ends>
            <span v-else>{{ticket[key]}}</span>
          </td>
        </tr>
      </slot>
    </tbody>
    <tfoot v-if="isTruncated">
      <tr>
        <td colspan="6">
          <a v-on:click="showAll" style="cursor: pointer;">
            {{$t("Show all")}}
            <i class="fas fa-angle-double-down" />
          </a>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import FormattedTime from 'shared/components/formatted-time.vue'
import PaymentInfo from 'shared/components/payment-info.vue'
import ReservationEnds from 'ticket-dashboard/components/reservation-ends.vue'
import TruncatedTickets from 'shared/mixins/truncated-tickets.js'
import UserProfileForTicket from 'shared/components/user-profile-for-ticket.vue'

export default {
  mixins: [TruncatedTickets],
  components: {FormattedTime, PaymentInfo, ReservationEnds, UserProfileForTicket },
  props: ["tickets", "limit", "columns", "initialSortBy", "initialSortDirection" ],
  data() {
    return {
      labels: {
        cancelledAt: this.$t("Cancelled at"),
        checkedInAt: this.$t("Checked-in at"),
        shortCode: this.$t("Code"),
        userName: this.$t("Name"),
        registeredAt: this.$t("Registered at"),
        reservationEndsAt: this.$t("Payment deadline"),
        ticketType: this.$t("Ticket"),
      },
      sortBy: this.initialSortBy || "userName",
      sortDirection: this.initialSortDirection || 1,
      unsortable: {
        actions: true,
      }
    }
  },
  computed: {
    sortedTickets() {
      return this.tickets.sort((ticketA,ticketB) => {
        var a = this.attributeCastedForSorting(ticketA)
        var b = this.attributeCastedForSorting(ticketB)
        if (a === null) {
          return -1 * this.sortDirection
        } else if (b === null) {
          return 1 * this.sortDirection
        } else if (a === b) {
          return 0
        } else {
          return (a > b ? 1 : -1) * this.sortDirection
        }
      })
    },
  },
  methods: {
    attributeCastedForSorting(ticket) {
      switch(this.sortBy) {
        case 'userName':
          return (ticket.userNameFurigana || "") + (ticket.userName || "")
        case 'ticketType':
          return (ticket.paymentInfo || "") + (ticket.ticketType.description || "") + (ticket.promoCode ? ticket.promoCode.code : "")
        default:
          return ticket[this.sortBy]
      }
    },
    setSortBy(key) {
      if(this.sortBy == key) {
        this.sortDirection = this.sortDirection * -1
      } else {
        this.sortBy = key
        this.sortDirection = 1
      }
    },
  },
}
</script>
