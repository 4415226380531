import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
Vue.use(Vuex)

import tickets from "store/tickets"

export default new Vuex.Store({
  modules: {
    tickets
  }
})
