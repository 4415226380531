import '@github/markdown-toolbar-element'
import waitForJQuery from 'wait-for-jquery'

waitForJQuery(() => {
  jQuery('.markdown-toolbar-container a[data-toggle="tab"][href^="#preview"]').on('shown.bs.tab mouseenter', function (e) {
    let navTabs = jQuery(this).parents(".nav-tabs")
    let writeTab = jQuery("a[href^='#write']", navTabs)
    let writeTabContent = jQuery(writeTab.attr("href"))
    let previewTab = jQuery("a[href^='#preview']", navTabs)
    let previewTabContent = jQuery(previewTab.attr("href"))
    if(!previewTabContent.data("loading")) {
      previewTabContent.data("loading", previewTabContent.html())
    }

    let textarea = jQuery("textarea", writeTabContent)
    let markdown = textarea.val()
    if(previewTabContent.data("markdown") != markdown) {
      previewTabContent.data("markdown", markdown)
      previewTabContent.html(previewTabContent.data("loading"))
      let previewUrl = navTabs.data("preview-url")
      jQuery.post({
        url: previewUrl,
        dataType: "html",
        data: {content: textarea.val()}
      }).done(responseText => {
        let html = jQuery("<div>").append( jQuery.parseHTML( responseText ) ).find( ".processed-markdown" )
        previewTabContent.html(html)
      })
    }
  })
})
