import waitForJQuery from 'wait-for-jquery'

waitForJQuery(() => {
  $(function(){
    $("#comments").load($("#comments a").attr("href") + "?hide_layout=true", function(){
      if(location.hash.match("#comment_")) {
        location.href = location.href
      }
    })
    $(document).on('click', '#comments a.comment-reply-link', function(e) {
      var target = $(e.target.hash)
      target.removeClass("hidden")
      $('html,body').animate({
        scrollTop: target.offset().top - ( $(window).height() - target.outerHeight(true) ) / 2
      }, 200)
    })
  })
})
