import Dropzone from 'dropzone'
import waitForJQuery from 'wait-for-jquery'

waitForJQuery(() => {
  $(".dropzone-textarea-wrapper textarea").on("focus", function(){
    $(this).parents(".dropzone-textarea-wrapper").addClass("dropzone-textarea-active")
  }).on("blur", function(){
    $(this).parents(".dropzone-textarea-wrapper").removeClass("dropzone-textarea-active")
  })
  $(".dropzone-textarea-wrapper").each((i, element)=>{
    var config = Object.assign({
      createImageThumbnails: false,
      previewsContainer: false,
      paramName: "group_file[file]",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      acceptedFiles: "image/jpeg,image/png,image/gif",
    }, $(element).data("dropzoneConfig"))
    var dropzone = new Dropzone(element, config)
    var textarea = $("textarea", element)
    var promptStatus = $(".dropzone-textarea-status .prompt", element)
    var uploadingStatus = $(".dropzone-textarea-status .uploading", element)
    var errorStatus = $(".dropzone-textarea-status .error", element)
    dropzone.on("success", (file, response) => {
      var markdown = `![${response.file_name}](${response.url})`

      var cursorPos = textarea.prop('selectionStart');
      var v = textarea.val();
      var textBefore = v.substring(0,  cursorPos);
      if(textBefore.length > 0 && textBefore.slice(-1) != "\n")
        textBefore += "\n"

      var textAfter  = v.substring(cursorPos, v.length);
      if (textAfter.length > 0 && textAfter.slice(0) != "\n")
        textAfter = "\n" + textAfter

      textarea.val(textBefore + markdown + textAfter);
    })
    dropzone.on("error", (file, errorMessage, xhr) => {
      errorStatus.text(errorMessage)
      errorStatus.removeClass("hidden")
    })
    dropzone.on("addedfile", (file) => {
      errorStatus.addClass("hidden")
      promptStatus.addClass("hidden")
      uploadingStatus.removeClass("hidden")
    })
    dropzone.on("queuecomplete", (file) => {
      uploadingStatus.addClass("hidden")
      promptStatus.removeClass("hidden")
    })
  })
})
