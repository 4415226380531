<template>
  <span>
    {{$tc("1 person | {count} people", count, {count})}}
  </span>
</template>

<script>
export default {
  props: ["count"]
}
</script>
