<template>
  <div>
    <div v-if="totalCapacity">
      {{count}} / <person-count :count="totalCapacity" />
    </div>
    <div v-else>
      <person-count :count="count" />
    </div>
    <div v-if="checkedInCount > 0">
      {{$tc("({count} checked-in)", checkedInCount, {count: checkedInCount})}}
    </div>
  </div>
</template>

<script>
import PersonCount from 'shared/components/person-count.vue'
export default {
  components: { PersonCount },
  props: ["totalCapacity", "count", "checkedInCount"]
}
</script>
