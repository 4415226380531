<template>
  <time v-if="formattedTime">{{formattedTime}}</time>
</template>

<script>
export default {
  props: ["time"],
  computed: {
    formattedTime() {
      return this.time && moment(this.time).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>
