<template>
  <div class="panel" v-if="tickets.length > 0">
    <div class="panel-heading panel-header-flex">
      <h2 class="panel-title">{{title}}</h2>
      <div class="right-item"><small><slot name="right-item" /></small></div>
    </div>
    <div class="panel-body"><slot /></div>
  </div>
</template>

<script>
export default {
  props: ["title", "tickets"],
}
</script>
