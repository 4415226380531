<template>
  <div class="panel" v-if="tickets.length > 0">
    <div class="panel-heading panel-header-flex">
      <h4 class="panel-title">{{title}}</h4>
      <div class="right-item"><small><slot /></small></div>
    </div>
    <ul class="ticket-list list-group">
      <li class="list-group-item" v-for="ticket in truncatedTickets">
        <div class="attendee-list-block">
          <user-profile-for-ticket :ticket="ticket" :hideEmail="true"></user-profile-for-ticket>
        </div>
      </li>
      <li v-if="isTruncated" class="list-group-item text-right">
        <a v-on:click="showAll" style="cursor: pointer;">
          {{$t("Show all")}}
          <i class="fas fa-angle-double-down" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import TruncatedTickets from 'shared/mixins/truncated-tickets.js'
import UserProfileForTicket from 'shared/components/user-profile-for-ticket.vue'
export default {
  mixins: [TruncatedTickets],
  components: { UserProfileForTicket },
  props: ["tickets", "title"],
  data() {
    return {
      limit: 4
    }
  },
  computed: {
    sortedTickets() {
      return this.tickets
    }
  },
}
</script>
