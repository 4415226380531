import Clipboard from 'clipboard'
import waitForJQuery from 'wait-for-jquery'

waitForJQuery(()=>{
  var selector = 'a[data-clipboard-text]'
  var clipboard = new Clipboard(selector)
  clipboard.on('success', (e) => {
    var trigger = $(e.trigger)
    trigger.data('before-copy-title', trigger.data("original-title"))
    trigger.attr('data-original-title', trigger.data('after-copy-title'))
    trigger.tooltip('show')
  })
  $(selector).on('hidden.bs.tooltip', () => {
    var beforeCopyTitle = $(this).data('before-copy-title')
    if ($(this).data('data-original-title') != beforeCopyTitle)
      $(this).attr('data-original-title',  beforeCopyTitle)
  })
})
